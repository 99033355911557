import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

const navToggle = document.querySelector(".mobile-nav-toggle");
const primaryNav = document.querySelector(".nav-items");

navToggle.addEventListener("click", () => {
    const visibility = primaryNav.dataset.visible;

    if (visibility === "false") {
        primaryNav.dataset.visible = "true";
        navToggle.setAttribute("aria-expanded", "true");
        navToggle.dataset.pressed = "true";
        navToggle.style.position = "fixed";
    } else {
        primaryNav.dataset.visible = "false";
        navToggle.setAttribute("aria-expanded", "false");
        navToggle.dataset.pressed = "false";
        navToggle.style.position = "absolute";
    }
});

// https://web.dev/reduce-javascript-payloads-with-code-splitting/#measure
// code splitting via dynamic imports after page loads
// https://elelad.medium.com/lazy-load-npm-packages-using-dynamic-import-fba19a44b1d
// https://web.dev/lazy-loading-video/
// https://blog.webdevsimplified.com/2021-03/dynamic-module-imports/
// https://developers.cloudflare.com/stream/examples/video-js/
window.addEventListener("load", (event) => {
    // loadVideos();
    // loadVideoPlayers();
    useIframeData();
    useGsap();
    import("@lottiefiles/lottie-player")
        .then((module) => module.default) // using the default export
        .then(() => useLottie())
        .catch((error) => console.log(error));
});

// const loadVideoPlayers = () => {
//     const lifeVid = {
//         selector: document.getElementById("lifeVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/a7b99f176869e917429c3103420b36cd/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };
//     const autoVid = {
//         selector: document.getElementById("autoVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/463680dbda82416709e7e652d6f6554a/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };
//     const farmVid = {
//         selector: document.getElementById("farmVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/07799165aa4bad4401ed3d1ffe1d9091/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };
//     const homeVid = {
//         selector: document.getElementById("homeVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/1250abe74ef0a8d47fdd9415393abecb/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };
//     const healthVid = {
//         selector: document.getElementById("healthVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/5e2356e04ce3c249f1aafc6225625aa9/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };
//     const quoteVid = {
//         selector: document.getElementById("quoteVid"),
//         autoplay: true,
//         muted: true,
//         preload: "auto",
//         loop: true,
//         width: "240",
//         height: "135",
//         controls: true,
//         sources: [
//             {
//                 src: "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/7f0d05ac00a9f31882982e6f28606fe1/manifest/video.m3u8",
//                 type: "application/x-mpegURL",
//             },
//         ],
//     };

//     // https://videojs.com/guides/options/
//     // https://videojs.com/guides/player-workflows/#accessing-a-player-that-has-already-been-created-on-a-page

//     const InsuranceVidArr = [
//         lifeVid,
//         autoVid,
//         farmVid,
//         homeVid,
//         healthVid,
//         quoteVid,
//     ];

//     InsuranceVidArr.forEach((video) => {
//         const player = videojs(video.selector, {
//             autoplay: video.autoplay,
//             muted: video.muted,
//             preload: video.preload,
//             loop: video.loop,
//             // width: video.width,
//             // height: video.height,
//             sources: video.sources,
//             controls: video.controls,
//             fill: true,
//             // fluid: true,
//             normalizeAutoplay: true,
//         });
//     });
// };

const useGsap = () => {
    // const pageOverlay = document.querySelector(".page-overlay");
    // const navItems = document.querySelectorAll(".nav-item");
    // const headerContent = document.querySelector(".header-content");
    // const mouse = document.querySelector(".mouse");
    // const sections = document.querySelectorAll(".section");
    // const header = document.querySelector(".header");
    // const navigation = document.querySelector(".navigation");
    // const lines = document.querySelectorAll(".line");
    gsap.registerPlugin(ScrollTrigger);

    let mainSections = gsap.utils.toArray(".section");
    mainSections.forEach((sec) => {
        let firstChildEl = sec.children[0];
        gsap.timeline({
            scrollTrigger: {
                trigger: sec,
                start: "top 85%",
                end: "top center",
                scrub: 3,
            },
        }).from(firstChildEl, {
            opacity: 0,
            x: -20,
        });
    });
};

const useLottie = () => {
    const animations = {
        "lottie-retirement":
            "https://assets5.lottiefiles.com/packages/lf20_3FIGvm.json",
        "lottie-employee":
            "https://assets6.lottiefiles.com/packages/lf20_7fCbvNSmFD.json",
        "lottie-annuities":
            "https://assets6.lottiefiles.com/packages/lf20_eebvtvut.json",
        "lottie-notary":
            "https://assets7.lottiefiles.com/packages/lf20_fnxj6oej.json",
    };
    const animationsArr = Object.entries(animations);

    animationsArr.forEach((animation) => {
        let player = document.getElementById(animation[0]);
        player.load(animation[1]);
    });

    // const tl = gsap.timeline({ defaults: { duration: 1 } });

    // tl.fromTo(pageOverlay, { x: 0 }, { x: "100%" })
    //     .fromTo(lines, { opacity: 0, scaleY: 0 }, { opacity: 1, scaleY: 1 })
    //     .fromTo(header, { opacity: 0, y: -60 }, { opacity: 1, y: 0 }, "-=1")
    //     .fromTo(navigation, { opacity: 0, y: -60 }, { opacity: 1, y: 0 }, "-=1")
    //     .fromTo(
    //         navItems,
    //         { opacity: 0, y: -60 },
    //         { opacity: 1, y: 0, stagger: 0.25 },
    //         "-=1.3"
    //     )
    //     .fromTo(
    //         headerContent,
    //         { opacity: 0, y: -60 },
    //         { opacity: 1, y: 0 },
    //         "-=1.5"
    //     )
    //     .fromTo(mouse, { opacity: 0 }, { opacity: 1 });
};

const useIframeData = () => {
    // iframeSources === { id : url }
    const iframeSources = {
        "health-insurance":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/5e2356e04ce3c249f1aafc6225625aa9/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
        "home-insurance":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/1250abe74ef0a8d47fdd9415393abecb/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
        "farm-insurance":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/07799165aa4bad4401ed3d1ffe1d9091/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
        "life-insurance":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/a7b99f176869e917429c3103420b36cd/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
        "auto-insurance":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/463680dbda82416709e7e652d6f6554a/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
        "quote-vid":
            "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/7f0d05ac00a9f31882982e6f28606fe1/iframe?muted=true&autoplay=true&loop=true&primaryColor=%23cd37e1",
    };

    const iframeSourcesArr = Object.entries(iframeSources);
    iframeSourcesArr.forEach((iframeSource) => {
        const preconstructedIframe = document.createElement("iframe");
        preconstructedIframe.src = iframeSource[1];
        preconstructedIframe.width = "640";
        preconstructedIframe.height = "360";
        preconstructedIframe.id = iframeSource[0];
        preconstructedIframe.allow =
            "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;";
        preconstructedIframe.loading = "lazy";

        const oldEl = document.getElementById(iframeSource[0]);
        const parentEl = oldEl.parentNode;
        parentEl.replaceChild(preconstructedIframe, oldEl);
    });
};

// const loadVideos = () => {
//     // videoData === { id : url }
//     const videoData = {
//         "health-insurance":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/5e2356e04ce3c249f1aafc6225625aa9/downloads/default.mp4",
//         "home-insurance":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/1250abe74ef0a8d47fdd9415393abecb/downloads/default.mp4",
//         "farm-insurance":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/07799165aa4bad4401ed3d1ffe1d9091/downloads/default.mp4",
//         "life-insurance":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/a7b99f176869e917429c3103420b36cd/downloads/default.mp4",
//         "auto-insurance":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/463680dbda82416709e7e652d6f6554a/downloads/default.mp4",
//         "quote-vid":
//             "https://customer-r2fjftg1pbiaobsf.cloudflarestream.com/7f0d05ac00a9f31882982e6f28606fe1/downloads/default.mp4",
//     };

//     const videoDataArr = Object.entries(videoData);
//     videoDataArr.forEach((vid) => {
//         const prebuiltVid = document.createElement("video");
//         prebuiltVid.src = vid[1];
//         prebuiltVid.width = "640";
//         prebuiltVid.height = "360";
//         // prebuiltVid.controls = true;
//         prebuiltVid.autoplay = true;
//         prebuiltVid.loop = true;
//         prebuiltVid.muted = true;
//         prebuiltVid.playsInline = true;

//         const placeholderEl = document.getElementById(vid[0]);
//         const parentEl = placeholderEl.parentNode;
//         parentEl.replaceChild(prebuiltVid, placeholderEl);
//     });
// };
